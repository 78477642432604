import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import Link from "next/link";
import Image from "next/image";
import Styles from "./menubar.module.css";
import MobileViewSubMenu from "./MobileViewSubMenu";
import { useRouter } from "next/router";
import en from "@/localization/en.json";
import { fetchAllBrands } from "repo/brand";
import { useSession } from "next-auth/react";
import { IS_BKASH_OFFER_MENU_ACTIVE } from "config/feature-flag/menubar";

function MobileView(props, ref) {
  const { menuOptions } = props;
  const router = useRouter();
  const { data: session } = useSession();
  const authToken = session?.token;

  const [allBrandsInfo, setAllBrandsInfo] = useState([]);
  const [sideMenuVisiblityClass, setSideMenuVisiblityClass] =
    useState("hidden");
  const [subMenuVisiblityClass, setSubMenuVisiblityClass] = useState("hidden");
  const [selectedmenuIndex, setSelectedMenuIndex] = useState(0);

  useImperativeHandle(ref, () => ({
    showSideMenu: () => setSideMenuVisiblityClass("visible"),
  }));

  useEffect(() => {
    fetchBrandData();
  }, []);

  const hideSideMenu = (event) => {
    event.preventDefault();
    setSideMenuVisiblityClass(
      sideMenuVisiblityClass === "visible" ? "hidden" : "visible"
    );
    setSubMenuVisiblityClass("hidden");
  };

  const onMenuSelect = (selectedSubMenuIndex) => {
    setSelectedMenuIndex(selectedSubMenuIndex);
    setSubMenuVisiblityClass("visible");
  };

  const hideChildMenu = () => {
    setSubMenuVisiblityClass("hidden");
  };

  async function fetchBrandData() {
    const data = await fetchAllBrands();
    if (data) {
      setAllBrandsInfo(data.data.brands);
    }
  }

  return (
    <div
      className={`fixed inset-0 bg-[#1B2D49]/40 min-[1200px]:hidden ${sideMenuVisiblityClass} z-[99999]`}
    >
      <div className="w-80 bg-white h-full opacity-100 pt-[1.5rem] pl-[2rem]">
        <div className={`${Styles.sidebarHideTransition}`}>
          <div className="flex justify-between mr-[1.5rem]">
            {!authToken && (
              <div
                className={`text-body2 text-grayD3 space-x-1 ${
                  subMenuVisiblityClass === "visible" ? "invisible" : "visible"
                }`}
              >
                <Link href="/signin">
                  <span>{en["common.sign_in"]}</span>
                </Link>
              </div>
            )}
            <Image
              src="/assets/icons/x.svg"
              width={12}
              height={12}
              alt="phone logo"
              className="cursor-pointer"
              onClick={hideSideMenu}
            />
          </div>

          <div
            className={`pt-[.75rem] ${
              subMenuVisiblityClass === "visible" ? "hidden" : "visible"
            } `}
          >
            <ul className="list-none mr-[1.5rem]">
              {menuOptions &&
                menuOptions.map((menu, index) => {
                  return (
                    <>
                      {menu.menus.length > 0 ? (
                        <li
                          key={menu.slug}
                          className="flex justify-between mt-[2.25rem]"
                          onClick={() => onMenuSelect(index)}
                        >
                          <p className="body1 font-medium text-grayD2">
                            {menu.name}
                          </p>
                          <Image
                            src="/assets/icons/right-arrow.svg"
                            width={8}
                            height={14}
                            alt="phone logo"
                          />
                        </li>
                      ) : (
                        <li className="mt-[2.25rem]" onClick={hideSideMenu}>
                          <Link href={`/category/${menu.slug}`}>
                            <p className="font-medium">{menu.name}</p>
                          </Link>
                        </li>
                      )}
                    </>
                  );
                })}

              <li
                className="flex justify-between mt-[2.25rem] cursor-pointer"
                onClick={() => onMenuSelect(-1)}
              >
                <p className="body1 font-medium text-grayD2">
                  {en["home.brands"]}
                </p>
                <Image
                  src="/assets/icons/right-arrow.svg"
                  width={8}
                  height={14}
                  alt="phone logo"
                />
              </li>
              {IS_BKASH_OFFER_MENU_ACTIVE && (
                <li className="mt-[2.25rem]">
                  <p
                    className="font-medium tracking-[.64px] pt-[.25rem] cursor-pointer"
                    onClick={(e) => {
                      router.push("/bkash-offer");
                      hideSideMenu(e);
                    }}
                  >
                    bKash Offer
                  </p>
                </li>
              )}

              <li className="flex items-center mt-[2.25rem]">
                <Image
                  src="/assets/icons/gift.svg"
                  width={18}
                  height={18}
                  alt="arrow"
                />

                <p
                  className="text-secondary text-center font-medium tracking-[.64px] ml-[.5rem] pt-[.25rem] cursor-pointer"
                  onClick={(e) => {
                    router.push("/offers");
                    hideSideMenu(e);
                  }}
                >
                  {en["home.offers"]}
                </p>
              </li>
            </ul>

            <div className="list-none mt-[3.625rem]">
              {/* <li className="text-grayD3 body2"></li> */}
              <div onClick={hideSideMenu}>
                <Link href="/my-wishlist">
                  <p className="text-grayD3 text-body2">
                    {en["common.wishlist"]}
                  </p>
                </Link>
              </div>
              {authToken && (
                <div>
                  <p className="text-grayD3 text-body2 mt-[1.625rem]">
                    <label
                      htmlFor="order-track-modal"
                      onClick={() => {
                        setSideMenuVisiblityClass("hidden");
                      }}
                    >
                      Track Order
                    </label>
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={`${subMenuVisiblityClass}`}>
            <MobileViewSubMenu
              selectedmenuIndex={selectedmenuIndex}
              submenuOptions={menuOptions?.[selectedmenuIndex]}
              allBrandsInfo={allBrandsInfo}
              onBackBtnClick={hideChildMenu}
              hideSideMenu={hideSideMenu}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(MobileView);
