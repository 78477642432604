import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { orderTrackByNumber } from "@/api/order";
import PrimaryBtn from "@/components/button/primaryBtn";
import {
  orderTrackingFormSchema,
  trackingId,
} from "@/utils/validation/schema/order";
import en from "@/localization/en.json";
import { createJwtToken } from "@/services/jsonwebtoken";
import { useUnAuthorizeOrderTrack } from "repo/order";

const TrackOrderForm = forwardRef(function TrackOrderForm(
  { onCloseFormRequest, onSuccessFullOrderSearch = () => {} },
  ref
) {
  const router = useRouter();
  const { data: session } = useSession();
  const authToken = session?.token;
  const { mutate: trackUnauthorizeUserOrder } = useUnAuthorizeOrderTrack({
    onSuccess: (orderData) => {
      const orderId = orderData?.data?.order?.id;
      router.push(`/order-track/${orderId}`);
    },
    onError: (errorData) => {
      let errorMessage = errorData?.errors[0]?.message || errorData?.message;
      setError(trackingId.name, { message: errorMessage });
    },
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        reset,
      };
    },
    []
  );

  const handleClick = (path) => {
    router.push(path);
    onCloseFormRequest(reset);
  };

  const validateOnSubmit = (data) => {
    if (
      data.tracking?.length === trackingId.charLength ||
      data.tracking?.length === trackingId.charLength - 1
    ) {
      if (!authToken) {
        return handleUnauthorizeUserOrderTrack(data);
      }

      onFormSubmit(data);
      return;
    }

    setError(trackingId.name, { message: en["order.order_id_minimum"] });
  };

  const handleUnauthorizeUserOrderTrack = (data) => {
    const payload = {
      ...data,
      token: createJwtToken(),
    };

    trackUnauthorizeUserOrder(payload);
  };

  const onFormSubmit = async (data) => {
    const res = await orderTrackByNumber(data);

    if (res.status === 200) {
      onSuccessFullOrderSearch();
      onCloseFormRequest(reset);
      router.push(`/my-orders/${res.data.data.order.id}`);
    } else if (res.status === 422) {
      res.data.errors.forEach(({ name, message }) => {
        setError(name, { message });
      });
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(orderTrackingFormSchema),
  });

  return (
    <div className="px-[0.7rem] py-[0.7rem] cursor-default">
      <p className="text-[#1F2A3B] mb-[0.5rem]">{en["common.order_id"]}</p>
      <form
        className="md:flex items-start"
        onSubmit={handleSubmit(validateOnSubmit)}
      >
        <div className="w-full md:w-[225px] min-h-[3rem]">
          <input
            type="text"
            placeholder="Your order ID"
            {...register("tracking")}
            className="w-full h-[3rem] rounded-[0.625rem] border-0 ring-1 ring-[#CBD0D6] focus:outline-none focus:ring-1 focus:ring-[#CBD0D6]"
          />
          {errors.tracking?.message && (
            <p className="text-[#FF4242] text-[0.875] mt-[0.35rem] first-letter:uppercase break-all">
              {errors.tracking?.message}
            </p>
          )}
        </div>

        <div className="mt-3 flex justify-between md:hidden">
          <div>
            <p
              className="text-[#FF7A00] underline cursor-pointer inline-block"
              onClick={() => handleClick("/faq")}
            >
              {en["order.need_help"]}
            </p>
          </div>

          <PrimaryBtn
            btnText={en["order.track"]}
            className="h-[3rem] w-[6.25rem] mt-2"
          />
        </div>

        <PrimaryBtn
          btnText={en["order.track_order"]}
          className="hidden md:block h-[3rem] w-[6.25rem] ml-[0.75rem]"
        />
      </form>
      <p
        className="text-[#FF7A00] underline mt-[0.5rem] cursor-pointer hidden md:inline-block"
        onClick={() => handleClick("/faq")}
      >
        {en["order.need_help"]}
      </p>
    </div>
  );
});

export default TrackOrderForm;
