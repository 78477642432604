import en from "@/localization/en.json";
import * as Yup from "yup";

export const shippingAddressSchema = Yup.object({
  note: Yup.string().max(250),
  cancellationReason: Yup.number().required(
    en["order.cancellationReason.required"]
  ),
  terms: Yup.boolean().oneOf([true], en["order.order_cancel_term_required"]),
});

export const trackingId = {
  name: "tracking",
  charLength: 13,
  charMinLength: 12,
};

export const orderTrackingFormSchema = Yup.object({
  tracking: Yup.string()
    .required(en["order.order_id.required"])
    .min(trackingId.charMinLength, en["order.order_id_minimum"])
    .max(trackingId.charLength, en["order.order_id_maximum"]),
});
