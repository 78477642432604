import en from "@/localization/en.json";

export const contactInfo = {
  phone1: en["checkout.phone_no_details"],
  phone2: "",
  checkoutPhone: en["checkout.phone_no_details"],
  email: "support@amarpet.com",
  officeAddress:
    "Ka-218, 9th floor, Zakir Complex, Kuril Chowrastha, Dhaka-1229",
};

export const socialMediaPagesLink = {
  facebook: "https://www.facebook.com/amarpetcom/",
  twitter: "",
  instagram: "https://www.instagram.com/amarpetbd/",
  youtube: "https://www.youtube.com/channel/UClEy2GdUpDP4mtpA6nlgsDQ/featured",
  tiktok: "https://www.tiktok.com/@amarpet",
};

export const PHONE_NUMBER = {
  CUSTOMER_SUPPORT: "+8801322908241",
};
